import React from "react"
import { styled } from "linaria/react"
import { colors } from "../theme"
import Link from "gatsby-link"

const Wrapper = styled(Link)`
  @media screen and (max-width: 480px) {
    padding: 15px 40px;
  }

  margin: ${props => props.margin || "default"};
  width: max-content;
  text-align: center;
  border-radius: 1px;
  background-color: ${props => props.bgColor || colors.black};
  padding: 15px 60px;
  border: ${props => props.border || "none"};
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
`

const Button = props => {
  return (
    <Wrapper
      weight={props.weight}
      color={props.color}
      bgColor={props.bgColor}
      border={props.border}
      type={props.type}
      width={props.width}
      margin={props.margin}
      to={props.link}
    >
      {props.text}
    </Wrapper>
  )
}

export default Button
